import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

import { ThemeContext } from "../../ThemeContext";

export const InfoHeader = React.forwardRef(({ bgColor, fontColor }, ref) => {
  const data = useStaticQuery(graphql`
    query SiteAddressQuery {
      site {
        siteMetadata {
          address
          phone
          email
        }
      }
    }
  `);

  const { colors, mobile } = useContext(ThemeContext);
  const { email, phone, address } = data.site.siteMetadata;

  return (
    <div
      id="info-header"
      style={styles.wrapper({ bgColor, fontColor, colors, mobile })}
      ref={ref}
    >
      <div style={styles.innerWrapper({ mobile })}>
        <p style={styles.infoSection}>
          <a
            style={styles.link}
            target="_blank"
            href="https://www.google.com/maps/place/R.+Vinte+e+Cinco+de+Julho,+1037+-+Rio+Branco,+Novo+Hamburgo+-+RS,+93310-251,+Brazil/@-29.6810026,-51.1410908,17z/data=!3m1!4b1!4m5!3m4!1s0x9519430a3d8edc8b:0x8a42469f2a25a5ec!8m2!3d-29.6810026!4d-51.1389021"
          >
            <FontAwesomeIcon icon={faMapMarkerAlt} style={styles.icon} />
            <span>{address}</span>
          </a>
        </p>
        <p style={styles.infoSection}>
          <a href={`tel:${phone}`} style={styles.link}>
            <FontAwesomeIcon icon={faPhoneAlt} style={styles.icon} />
            <span>{phone}</span>
          </a>
        </p>
        <p style={styles.infoSection}>
          <a href={`mailto:${email}`} style={styles.link}>
            <FontAwesomeIcon icon={faEnvelope} style={styles.icon} />
            <span>{email}</span>
          </a>
        </p>
      </div>
    </div>
  );
});

const styles = {
  wrapper: ({ colors, bgColor, fontColor, mobile }) => ({
    backgroundColor: bgColor || colors.green,
    color: fontColor || colors.white,
    padding: "8px",
    paddingBottom: mobile ? "32px" : "8px",
    display: "flex",
    justifyContent: "center",
    zIndex: "999",
    position: "relative",
    fontSize: "16px",
    fontWeight: "bold",
    fontFamily: "bahnschrift",
  }),
  innerWrapper: ({ mobile }) => ({
    maxWidth: "1200px",
    width: "100%",
    display: "flex",
    flexDirection: mobile ? "column" : "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  }),
  infoSection: {
    display: "flex",
    margin: "0 16px",
  },
  icon: {
    marginRight: "8px",
    width: "16px",
    height: "16px",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    display: "flex",
    alignItems: "center",
  },
};
