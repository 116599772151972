import React, { useEffect, useState, useContext, useRef, useMemo } from "react";

import { InfoHeader } from "./InfoHeader";
import { ThemeContext } from "../../ThemeContext";
import Nav from "./Nav";

const styles = {
  header: {
    marginBottom: `40px`,
  },
};

const Header = () => {
  const { mobile, margin, setScrolled } = useContext(ThemeContext);

  const infoHeaderRef = useRef();

  const getInfoHeaderHeight = () =>
    (infoHeaderRef.current &&
      infoHeaderRef.current.getBoundingClientRect().height) ||
    0;

  const resizeHandler = () => {
    setScrolled((!window.scrollY && getInfoHeaderHeight()) || 0 + margin);
  };

  const scrollHandler = () => {
    const paddingTop = getInfoHeaderHeight() + margin;
    if (window.scrollY >= paddingTop) {
      setScrolled(margin);
    } else {
      setScrolled(paddingTop - window.scrollY - margin);
    }
  };

  useEffect(() => {
    resizeHandler();
  }, [infoHeaderRef, mobile]);

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", scrollHandler);
    return () => {
      document.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <header style={styles.header}>
      {!mobile && <InfoHeader ref={infoHeaderRef} />}
      <Nav />
    </header>
  );
};

export default Header;
