import React, { useState, useContext, useRef } from "react";
import { Link } from "gatsby";

import Logo from "../Logo";
import { ThemeContext } from "../../ThemeContext";

const navLinks = [
  { name: "Home", linkTo: "/" },
  { name: "Empresa", linkTo: "/about/" },
  { name: "Serviços", linkTo: "/services/" },
  { name: "Contato", linkTo: "/contact/" },
];

const Nav = () => {
  const { colors, error, mobile, scrolled } = useContext(ThemeContext);
  const [showMenu, setShowMenu] = useState(false);
  const containerRef = useRef();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <>
      <div
        ref={containerRef}
        style={styles.navWrapper({
          scrolled,
          colors,
        })}
      >
        <nav style={styles.nav({ mobile })}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Link to="/" style={styles.logoLink}>
              <Logo withName />
            </Link>

            {mobile && (
              <div style={styles.hamburgerMenu} onClick={toggleMenu}>
                <span style={styles.hamburgerMenuItem}></span>
                <span style={styles.hamburgerMenuItem}></span>
                <span style={styles.hamburgerMenuItem}></span>
              </div>
            )}
          </div>
          {(!mobile || showMenu) && (
            <ul style={styles.linksList({ colors, mobile })}>
              {navLinks.map((link, index) => (
                <li
                  key={link.name}
                  style={styles.linkItem({ mobile, colors, index })}
                >
                  <Link
                    to={link.linkTo}
                    style={styles.link}
                    activeStyle={{
                      borderBottom: `2px solid ${colors.white}`,
                    }}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </nav>
        {error.error && (
          <p
            style={styles.errorMessage({
              navHeight:
                (containerRef.current &&
                  containerRef.current.getBoundingClientRect().height) ||
                0 + scrolled,
              colors,
            })}
          >
            {error.message}
          </p>
        )}
      </div>
    </>
  );
};

const styles = {
  navWrapper: ({ scrolled, colors }) => ({
    display: "flex",
    justifyContent: "center",
    paddingTop: `${scrolled}px`,
    position: "fixed",
    top: "0",
    width: "100%",
    zIndex: "99",
    backgroundColor: colors.lightGreen,
    fontSize: "22px",
    boxShadow: "0 0 8px 2px rgba(0,0,0,0.7)",
  }),
  nav: ({ mobile }) => ({
    display: "flex",
    flexDirection: mobile ? "column" : "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "8px",
    width: "100%",
    maxWidth: "1200px",
  }),
  hamburgerMenu: {
    display: "inline",
    cursor: "pointer",
    paddingRight: "16px",
  },
  hamburgerMenuItem: {
    display: "block",
    width: "33px",
    height: "4px",
    marginBottom: "5px",
    position: "relative",
    background: "#cdcdcd",
    borderRadius: "3px",
  },
  linksList: ({ colors, mobile }) => ({
    listStyle: "none",
    display: "flex",
    width: mobile ? "100%" : null,
    padding: mobile ? null : "0 16px",
    paddingTop: mobile ? "16px" : null,
    color: colors.white,
    flexDirection: mobile ? "column" : "row",
    userSelect: "none",
  }),
  linkItem: ({ mobile, colors }) => ({
    margin: mobile ? 0 : "0 16px",
    textAlign: "center",
    padding: mobile ? "8px 0" : 0,
    borderTop: mobile ? `1px solid ${colors.green}` : "none",
  }),
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  logoLink: {
    textDecoration: "none",
    padding: "8px 16px",
  },
  errorMessage: ({ navHeight, colors }) => ({
    position: "fixed",
    top: `${navHeight}px`,
    left: "0",
    padding: "8px",
    backgroundColor: "tomato",
    width: "100%",
    zIndex: "9",
    textAlign: "center",
    color: colors.white,
  }),
};

export default Nav;
