import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { ThemeContext } from "../../ThemeContext";

const Name = ({ title, color }) => (
  <p style={{ ...styles.name, color }}>{title}</p>
);

const Logo = ({ withName, dark, style }) => {
  const data = useStaticQuery(graphql`
    query {
      logoRoundWhite: file(relativePath: { eq: "logoRoundWhite.png" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logoWithNameWhite: file(relativePath: { eq: "logoWithNameWhite.png" }) {
        childImageSharp {
          fixed(width: 200, height: 63) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logoWithNameDark: file(relativePath: { eq: "logoWithNameDark.png" }) {
        childImageSharp {
          fixed(width: 400, height: 126) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div style={styles.wrapper}>
      {withName ? (
        <Img
          fixed={
            data[dark ? "logoWithNameDark" : "logoWithNameWhite"]
              .childImageSharp.fixed
          }
          style={style ? style : {}}
        />
      ) : (
        <Img
          fixed={data.logoRoundWhite.childImageSharp.fixed}
          style={styles.img}
        />
      )}
    </div>
  );
};

const styles = {
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  img: { width: "200px", height: "63px" },
  name: {
    marginLeft: "16px",
  },
};

export default Logo;
