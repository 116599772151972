import React, { useContext } from "react";
import Logo from "../Logo";
import { ThemeContext } from "../../ThemeContext";

export const InfoFooter = () => {
  const { colors, mobile } = useContext(ThemeContext);
  return (
    <div
      style={{
        fontSize: "30px",
        padding: mobile ? "8px" : "20px",
        margin: mobile ? "8px" : "80px",
        maxWidth: "1200px",
        textAlign: mobile ? "center" : "left",
      }}
    >
      <Logo
        withName
        dark
        style={{ width: "200px", height: "63px", margin: mobile && "0 auto" }}
      />
      <p
        style={{
          marginTop: "30px",
          color: colors.lightBlue,
        }}
      >
        Aute ea nisi id aliqua nulla nostrud officia anim. Veniam eu anim mollit
        voluptate elit dolore ipsum. In elit pariatur labore consequat nulla
        proident elit elit qui voluptate. Velit exercitation aliqua sint ipsum
        cillum duis laborum ex culpa nostrud.
      </p>
    </div>
  );
};
