import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";

import Header from "./Header";
import { Footer } from "./Footer";
import SEO from "./seo";

import "../assets/fonts.css";
import "./styles.css";
import { ThemeContext } from "../ThemeContext";

const Layout = ({ children, pageTitle }) => {
  const { colors, mobile } = useContext(ThemeContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [mobile]);

  return (
    <div
      style={{
        backgroundColor: colors.white,
      }}
    >
      <SEO title={pageTitle} />
      <Header />
      <main
        style={{
          margin: `0 auto`,
          maxWidth: 1200,
          minHeight: "calc(100vh - 450px)",
          padding: `60px 20px 20px`,
          textAlign: "center",
        }}
      >
        {children}
      </main>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
