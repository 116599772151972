import React, { useContext } from "react";
import { InfoFooter } from "./InfoFooter";
import { ThemeContext } from "../../ThemeContext";
import { InfoHeader } from "../Header/InfoHeader";

export const Footer = ({ showInfo = true }) => {
  const { colors, mobile } = useContext(ThemeContext);

  return (
    <footer
      style={{
        width: "100%",
        backgroundColor: colors.grey,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {showInfo && <InfoFooter />}
      {mobile && <InfoHeader bgColor={colors.grey} />}

      <p
        style={{
          backgroundColor: colors.green,
          color: colors.white,
          padding: "12px 12px",
          width: "100%",
          textAlign: "center",
        }}
      >
        © {new Date().getFullYear()} Todos os direitos reservados - Probus
        Gestão Contábil
      </p>
    </footer>
  );
};
